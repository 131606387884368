<template>
    <transition name="translate" mode="out-in">
        <component 
            v-if="authConfig" 
            :setForgotStep="setForgotStep"
            :setGlobalForm="setGlobalForm"
            :globalForm="globalForm"
            :is="formWidget" />
    </transition>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            usersAuth: state => state.user.usersAuth,
            loginProgress: state => state.loginProgress
        }),
        authConfig() {
            return this.$store.state.user.authConfig
        },
        formWidget() {
            switch (this.forgotStep) {
            case 1:
                return () => import('./StartForm.vue')
                break;
            case 2:
                return () => import('./CheckCode.vue')
                break;
            case 3:
                return () => import('./SetPassword.vue')
                break;
            default:
                return () => import('./StartForm.vue')
            }
        }
    },
    data() {
        return {
            forgotStep: 1,
            globalForm: null
        }
    },
    methods: {
        setForgotStep(value) {
            this.forgotStep = value
        },
        setGlobalForm(value) {
            this.globalForm = value
        }
    }
}
</script>